<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Add Account</h1>
          <v-form ref="investmentForm">
            <v-row>
              <v-col md="12" class="py-0">
                <v-label>Role</v-label>
                <v-select
                  v-model="role"
                  :items="roles"
                  item-text="name"
                  item-value="value"
                  outlined
                  placeholder="Role"
                ></v-select>
              </v-col>
              <v-col md="12" class="py-0">
                <v-label v-if="role == 'user'">Fullname</v-label>
                <v-label v-if="role == 'business'">Business Name</v-label>
                <v-text-field
                  v-model="fullname"
                  outlined
                  :placeholder="role != 'business' ? 'Fullname' : 'Business Name'"
                  class="mt-1"
                ></v-text-field>
              </v-col>

              <v-col md="12" class="py-0">
                <v-label v-if="role == 'user'">Email Address</v-label>
                <v-label v-if="role == 'business'">Business Email</v-label>
                <v-text-field
                  v-model="email"
                  outlined
                  :placeholder="
                    role != 'business' ? 'Email Address' : 'Business Email'
                  "
                  class="mt-1"
                ></v-text-field>
              </v-col>
              <v-col md="12" class="py-0" v-if="role == 'business'">
                <v-label>Registration Number</v-label>
                <v-text-field
                  v-model="registrationNumber"
                  outlined
                  placeholder="Registration Number"
                  class="mt-1"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn @click="validate" v-if="!loading" large>Add <span v-if="role == 'business'">Business</span> <span v-if="role != 'business'">User</span></v-btn>
            <v-btn v-if="loading" large loading></v-btn>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "AddAccount",
  data: () => ({
    myCroppa: {},
    postType: undefined,
    imageUrl: undefined,
    video: undefined,
    email: undefined,
    image: undefined,
    loading: false,
    fullname: undefined,
    registrationNumber: undefined,
    roles: [
      {
        name: "User",
        value: "user",
      },
      {
        name: "Editor",
        value: "editor",
      },
      {
        name: "Admin",
        value: "admin",
      },
      {
        name: "Business",
        value: "business",
      },
    ],
    role: undefined,
  }),
  methods: {
    ...mapActions(["postUser"]),
    async validate() {
      this.loading = true;
      let formData = {};
      if (this.fullname != undefined) {
        formData.fullname = this.fullname;
      }
      if (this.email != undefined) {
        formData.email = this.email;
      }
      if (this.role != undefined) {
        formData.role = this.role;
      }
      if (this.$route.params.id != undefined) {
        formData._id = this.$route.params.id;
      }
      if (this.registrationNumber) {
        formData.registrationNumber = this.registrationNumber;
      }

      await this.postUser(formData);

      this.$router.push("/business/accountList");

      this.loading = false;
    },

    onChangeImageWeb() {
      if (this.imageWeb != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageWebUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageWeb);
      } else {
        this.imageWebUrl = undefined;
      }
    },
    onChangeImageApp() {
      if (this.imageApp != undefined) {
        const fileReader = new FileReader();
        fileReader.addEventListener("load", () => {
          this.imageAppUrl = fileReader.result;
        });
        fileReader.readAsDataURL(this.imageApp);
      } else {
        this.imageAppUrl = undefined;
      }
    },
    onImageWebPick() {
      this.imageWebUrl = undefined;
    },
    onImageAppPick() {
      this.imageAppUrl = undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.imageBox {
  display: flex;
  flex-flow: column;
  margin-bottom: 20px;
  .v-label {
    margin-bottom: 10px;
  }
  img {
    border: 1px solid #efefef;
    width: 200px;
    height: 200px;
    object-fit: contain;
    border-radius: 10px;
    margin-right: 10px;
  }
}
</style>